import _throttle from 'lodash.throttle'

import { SCREEN_WIDTH_SIZES } from '@packages/screen/constants/sizes.js'

export const DEFAULT_SCREEN_SIZE = 1280

export default {
  data () {
    return {
      _sw_throttle: 200,
      _sw_windowWidth: 0,
      _sw_listener: null,
      _sw_listenerFn: null
    }
  },

  computed: {
    screenWidth () {
      return this._sw_windowWidth || DEFAULT_SCREEN_SIZE
    },

    xs () {
      return this._sw_windowWidth <= SCREEN_WIDTH_SIZES.xs
    },

    sm () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.xs && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.sm
    },

    md () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.sm && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.md
    },

    lg () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.md && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.lg
    },

    xl () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.lg && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.xl
    },

    mxl () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.xl && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.mxl
    },

    xxl () {
      return this._sw_windowWidth > SCREEN_WIDTH_SIZES.mxl && this._sw_windowWidth <= SCREEN_WIDTH_SIZES.xxl
    },

    currentBreakpoint () {
      return Object.entries(SCREEN_WIDTH_SIZES).reverse().find(([, size]) => this._sw_windowWidth > size)?.[0]
    }
  },

  mounted () {
    this._sw_updateWidth()
    this._sw_listener = this._sw_throttle ? _throttle(this._sw_updateWidth, this._sw_throttle) : this._sw_updateWidth
    window.addEventListener('resize', this._sw_listener)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this._sw_listener)
  },

  methods: {
    setResizeListener (listenerFn) {
      this._sw_listenerFn = listenerFn
      this._sw_updateWidth()
    },

    setThrottle (throttle) {
      this._sw_throttle = throttle
    },

    _sw_updateWidth () {
      if (!process?.browser) {
        this._sw_windowWidth = DEFAULT_SCREEN_SIZE
        return
      }
      if (window.innerWidth === this._sw_windowWidth) {
        return
      }

      this._sw_windowWidth = window.innerWidth

      if (this._sw_listenerFn) {
        this._sw_listenerFn(this._sw_windowWidth)
      }
    },

    isWider (size) {
      return typeof size === 'number' ? this._sw_windowWidth >= size : this._sw_windowWidth > SCREEN_WIDTH_SIZES[size]
    },

    isNarrower (size) {
      return typeof size === 'number' ? this._sw_windowWidth < size : this._sw_windowWidth <= SCREEN_WIDTH_SIZES[size]
    },

    isBetween (size1, size2) {
      let val1 = typeof size1 === 'number' ? size1 : SCREEN_WIDTH_SIZES[size1]
      let val2 = typeof size2 === 'number' ? size2 : SCREEN_WIDTH_SIZES[size2]

      if (val1 > val2) {
        [val1, val2] = [val2, val1]
      }

      return this.isWider(val1) && this.isNarrower(val2)
    },

    getClosestBreakpoint (breakpoints) {
      return breakpoints
        .sort((a, b) => SCREEN_WIDTH_SIZES[a] > SCREEN_WIDTH_SIZES[b] ? -1 : 1)
        .find(breakpoint => this._sw_windowWidth > SCREEN_WIDTH_SIZES[breakpoint])
    }
  },
}
